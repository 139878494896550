import { useRef, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import right from '../../assets/11. FICHA/right.svg';
import left from '../../assets/11. FICHA/left.svg';
// import Modal from 'react-modal';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import { isMobile } from 'react-device-detect';
import translate from '../../components/languages';

const CheckoutCarousel = ({
  items,
  pureItems,
  setPagination = false,
  infinite = true,
  showArrows = false,
  showAllBtn = true,
}) => {
  const [index, setIndex] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMobileIsOpen, setModalMobileIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const carouselFull = useRef(null);

  const responsive = {
    0: { items: 1 },
    678: { items: 3 },
    1280: { items: 3 },
    // 1350: { items: 4 },
    // 2000: { items: 5 },
    // 2500: { items: 6 },
    // 2800: { items: 7 },
  };

  const renderSlideInfo = ({ item, itemsCount }) => {
    if (setPagination) setPagination(`${item}\\${itemsCount}`);
  };

  const changeSlide = (nextIndex) => {
    const newIndex = index + nextIndex;
    if (newIndex < 0 || newIndex >= items.length) return;
    setIndex(newIndex);
    setCurrentIndex(newIndex);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'initial';
    document.body.style.height = 'initial';
  };

  const openModal = () => {
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  };

  const renderNextButton = ({ isDisabled }) => {
    return (
      <img
        src={right}
        alt="next button"
        className="alice-custom-right-btn"
        style={{ opacity: isDisabled ? '0.5' : 1 }}
      />
    );
  };

  const renderPrevButton = ({ isDisabled }) => {
    return (
      <img
        src={left}
        className="alice-custom-left-btn"
        alt="previous button"
        style={{
          width: '16px',
          opacity: isDisabled ? '0.5' : 1,
          position: 'absolute',
          left: '5vw',
          bottom: '-12px',
        }}
      />
    );
  };

  const pureItemsImg = pureItems.map((eachPhoto) => {
    return isMobile
      ? eachPhoto.mediumScaleImageUrl
      : eachPhoto.originalImageUrl;
  });

  console.log('images', pureItems);

  return (
    <div style={{ position: 'relative' }}>
      {showAllBtn && items.length > 0 && (
        <button className="view-all-propics" onClick={() => openModal(true)}>
          {translate.t('checkoutSeection.seeAllPhotos')}
        </button>
      )}

      <div className="container-top-carousel ">
        <AliceCarousel
          mouseTracking
          items={items}
          infinite={infinite}
          renderPrevButton={showArrows && renderPrevButton}
          renderNextButton={showArrows && renderNextButton}
          responsive={responsive}
          animationType="fadeout"
          disableButtonsControls={showArrows ? false : true}
          renderSlideInfo={renderSlideInfo}
          renderDotsItem={false}
          disableDotsControls={true}
          disableSlideInfo={false}
        />
      </div>

      {modalIsOpen && (
        <FullScreenCarousel onClose={closeModal} images={pureItemsImg} />
      )}
    </div>
  );
};

export default CheckoutCarousel;

const FullScreenCarousel = ({ onClose, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="full-div-carousel">
      <button onClick={onClose} className="carousel-button close-btn">
        &times;
      </button>
      <button onClick={goToPrevious} className="carousel-button prev">
        &#9664;
      </button>
      <div className="carousel-image-container">
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex}`}
          className="carousel-image"
        />
      </div>
      <button onClick={goToNext} className="carousel-button next">
        &#9654;
      </button>
    </div>
  );
};
