import React, { useState } from 'react';
import './Carousel.css'; // Archivo CSS para estilos
import { BrowserView, MobileView } from 'react-device-detect';
import CheckoutCarousel from './CheckoutCarousel';
import translate from '../../components/languages';

const Carousel = ({ photosRoomList, purePhotosRoomArray }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerSlide = 3; // Número de elementos por slide
  const currentPage = Math.ceil((currentIndex + 1) / itemsPerSlide);
  const totalPages = Math.ceil(photosRoomList.length / itemsPerSlide);
  const [pagination, setPagination] = useState(currentPage + '/' + totalPages);

  const nextSlide = () => {
    const current = Math.ceil((currentIndex + 1) / itemsPerSlide);
    const total = Math.ceil(photosRoomList.length / itemsPerSlide);

    if (current <= total - 1) {
      const nextIndex =
        currentIndex === photosRoomList.length - itemsPerSlide
          ? 0
          : currentIndex + itemsPerSlide;
      setCurrentIndex(nextIndex);
      setPagination(nextIndex - 1 + '/' + totalPages);
    }
  };

  const prevSlide = () => {
    if (currentIndex !== 0) {
      const prevIndex =
        currentIndex === 0
          ? photosRoomList.length - itemsPerSlide
          : currentIndex - itemsPerSlide;
      setCurrentIndex(prevIndex);
      setPagination(prevIndex + 1 + '/' + totalPages);
    }
  };

  return (
    <div className="checkout-sleep-section">
      <div className="title-description mb-2 checkout-mobile-container include-title-green-100">
        <p>{translate.t('sleepingZone')}</p>
      </div>
      <div className="checkout-bed-carousel">
        <BrowserView>
          <span
            style={{
              position: 'absolute',
              top: 0,
              right: '30px',
              fontFamily: 'SharpGrotesk',
              fontSize: '13px',
            }}
          >
            {pagination}
          </span>
        </BrowserView>

        <BrowserView>
          <div className="checkout-alt-carousel-container">
            <button className="checkout-alt-prev" onClick={prevSlide}>
              &#10094;
            </button>
            <button className="checkout-alt-next" onClick={nextSlide}>
              &#10095;
            </button>
            <div className="checkout-alt-carousel-wrapper">
              <div className="checkout-alt-carousel">
                {photosRoomList
                  .slice(currentIndex, currentIndex + itemsPerSlide)
                  .map((photo, index) => photo)}
              </div>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div style={{ paddingLeft: '30px' }}>
            <div
              style={{
                marginLeft: '34%',
                marginBottom: '20px',
                position: 'relative',
              }}
            >
              <span
                className="checkout-pagination"
                style={{
                  fontFamily: 'SharpGrotesk',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  padding: '29px',
                }}
              >
                {pagination}
              </span>
            </div>
            <CheckoutCarousel
              items={photosRoomList}
              pureItems={purePhotosRoomArray}
              setPagination={setPagination}
              infinite={false}
              showArrows={true}
              showAllBtn={false}
            />
          </div>
        </MobileView>
      </div>
    </div>
  );
};

export default Carousel;
