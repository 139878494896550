export const FB = {
  viewContent: function () {
    console.log('attempt to trigger viewContet');
    if (typeof window.fbq !== 'undefined') {
      const params = {};
      window.fbq('track', 'ViewContent', params);
      window.gtag('event', 'ViewContent', params);
      console.log('trigger viewContet');
    } else {
      console.log('no fbq defined');
    }
  },
  initiateCheckout: function () {
    console.log('attempt to trigger initiateCheckout');
    if (typeof window.fbq !== 'undefined') {
      const params = {};
      window.fbq('track', 'InitiateCheckout', params);
      window.gtag('event', 'begin_checkout', params);
      console.log('trigger initiateCheckout');
    } else {
      console.log('no fbq defined');
    }
  },
  lead: function () {
    console.log('attempt to trigger lead');
    if (typeof window.fbq !== 'undefined') {
      const params = {};
      window.fbq('track', 'Lead', params);
      window.gtag('event', 'Lead', params);
      console.log('trigger lead');
    } else {
      console.log('no fbq defined');
    }
  },
};
