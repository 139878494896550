import { useEffect, useState } from 'react';
import translate from '../../components/languages';
import { Modal, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import banner from '../../assets/banner_promo.png';
import closeFilterIcon from '../../assets/icons/closeFilterIcon_1.svg';

import logo from '../../assets/10. NOSOTROS/logolined.svg';

export default function PromotionModal() {
  const key = `promotionalModalOff`;
  const history = useHistory();
  const showModal = localStorage.getItem(key);

  const [showModalState, setShow] = useState(true);

  const preventShowModal = () => {
    setShow(false);
    localStorage.setItem(key, 'true');
  };
  useEffect(() => {
    console.log('llegamos');
  }, [showModalState]);

  function goToPropertyList() {
    history.push('/properties');
  }

  if (showModal === 'true' || showModalState === false) return null;

  return (
    <>
      <Modal
        show={showModalState}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-no-border modal-custom-border"
      >
        <div
          className="position-absolute "
          style={{ zIndex: 9999, right: 60, top: 30 }}
        >
          <button
            className="btn btn-secondary full-carousel-btn-left"
            onClick={(e) => setShow(false)}
          >
            <img
              src={closeFilterIcon}
              width={20}
              height={25}
              alt="close modal"
              className=""
            />
          </button>
        </div>

        <Modal.Body>
          <Col>
            <Row>
              <div className="col-xs-12 col-md-6 no-padding">
                <img
                  width={'100%'}
                  alt="promotion code to book now"
                  src={banner}
                />
              </div>
              <div
                className="col-xs-12 col-md-6  d-flex align-items-center pb-4 pt-4"
                style={{ position: 'relative' }}
              >
                <div className="text-center">
                  <h3>¡{String(translate.t('homePromoBanner.title'))}!</h3>
                  <p>
                    {renderWithHTML(
                      String(translate.t('homePromoBanner.body')),
                    )}
                  </p>
                  <p>{String(translate.t('homePromoBanner.extra'))}</p>
                  <button
                    className="button w-100 uppercase"
                    style={{ height: 30 }}
                    onClick={() => {
                      goToPropertyList();
                    }}
                  >
                    {String(translate.t('homePromoBanner.bookNow'))}
                  </button>

                  <br />
                  <span
                    className="mt-4 d-block text-decoration-underline cursor-pointer"
                    onClick={() => preventShowModal()}
                  >
                    {String(translate.t('homePromoBanner.btnSkipShow'))}
                  </span>
                </div>
                <div className="back-logo">
                  <img src={logo} className="team-logo" alt="logo" />
                </div>
              </div>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
}

export function renderWithHTML(text: string) {
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
}
